
/**
 * @name: 系统管理-软件更新
 * @author: Mobai
 * @date: 2024-05-31 17:53
 * @description： 系统管理-软件更新
 * @update: 2024-05-31 17:53
 */
import { Vue, Component } from "vue-property-decorator"
import { appVersionQueryApi, appVersionDetailApi, appVersionCreateApi } from "@/apis/systemManage/update"
import type { IUpdate } from "@/apis/systemManage/update/types"
import type { ICrudOption } from "@/types/m-ui-crud"
import type { IPage } from "@/apis/page";
import { deepCopy } from "@/utils/common";

@Component({})
export default class systemManageUpdate extends Vue {
  // 表格加载状态
  tableLoading: boolean = true;
  // 表格数据
  tableData: IUpdate[] = []
  // 表格总数
  tableTotal = 0
  // 查询参数
  queryParam: IPage = {
    page: 1,
    limit: 10
  }
  // 表单参数
  modelForm: Partial<IUpdate> = {}
  // crud配置信息
  crudOption: ICrudOption = {
    searchBox: false,
    menu: false,
    dialogWidth: '600px',
    column: [
      {
        label: "ID",
        prop: "id",
        width: 180,
        align: "left",
        addHide: true,
        slot: true
      },
      {
        label: "类型",
        prop: "type",
        align: "center",
        type: 'select',
        width: 100,
        dicData: [
          {
            label: 'android',
            value: 1
          },
          {
            label: 'ios',
            value: 2
          }
        ]
      },
      {
        label: "版本号",
        prop: "editionNumber",
        align: "center",
        rules: [
          {required: true, message: "请输入版本号", trigger: "blur"},
          {
            validator: (rule: any, value: string, callback: Function) => {
              if (!/^[1-9]\d*$/.test(value)) {
                return callback(new Error('请输入正整数'))
              }
              callback()
            },
            trigger: 'blur'
          }
        ],
        width: 100
      },
      {
        label: "版本名称",
        prop: "editionName",
        align: "center",
        rules: [
          {required: true, message: "请输入版本名称", trigger: "blur"}
        ],
        width: 100
      },
      {
        label: '强制更新',
        prop: 'editionForce',
        type: 'switch',
        width: 70,
        span: 24,
        align: 'center',
        dicData: [
          {
            label: '是',
            value: true
          },
          {
            label: '否',
            value: false
          }
        ]
      },
      {
        label: "地址",
        prop: "editionUrl",
        align: "center",
        span: 24,
        overHidden: true,
        addSlot: true,
        rules: [
          {required: true, message: "请输入地址", trigger: "blur"}
        ]
      },
      {
        label: "备注",
        prop: "description",
        type: 'textarea',
        span: 24,
        overHidden: true,
        maxlength: 50,
        rules: [
          {required: true, message: "请输入备注", trigger: "blur"}
        ]
      }
    ]
  }

  getList () {
    this.tableLoading = true
    appVersionQueryApi(this.queryParam).then(e => {
      this.tableData = e.list;
      this.tableTotal = e.total
      this.tableLoading = false
    })
  }

  rowSave (form: IUpdate, done: Function, loading: Function) {
    const data: IUpdate = deepCopy(form)
    if (data.editionUrl) {
      data.editionUrl = form.editionUrl.replace(/^((https|http)?:\/\/)([^\/]+)\//, `${location.protocol}//${location.host}/api/party/oss/download/`)
    }
    // @ts-ignore
    if (data.editionForce === '' || data.editionForce === null) {
      data.editionForce = false
    }
    data.editionIssue = true
    data.editionSilence = false
    appVersionCreateApi(data).then(e => {
      if (e) {
        this.$message.success("操作成功!")
        this.getList()
        done()
      }
    }).finally(() => {
      loading()
    })
  }

  created () {
    this.getList()
  }
}
